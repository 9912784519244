import { EngineStore } from '~/stores/engine'

export class PlanTags {
  static async use() {
    const { getComponentContent } = Cms.use()
    const { content } = await getComponentContent('PlanTraits', null)
    const engine = EngineStore.use()

    const hydratedTags = computed(() => _mapKeys(content.value.ext, (_, key) => _camelCase(key)))

    const tagCatalog = computed<Record<string, { text: string, key: string }[]>>(() => _mapValues(engine.quotesCatalog,
      (val) => _compact(val.planAttributes.map(attr => {
        const tagText = hydratedTags.value[attr]
        if(_isNil(tagText)) return null
        return {
          key: attr,
          text: tagText
        }
      }))))

    const commonTagsForIds = (ids: string[]) => {
      return _intersectionBy(...Object.values(_pick(tagCatalog.value, ids)), x => x.key)
    }

    const allTagsForIds = (ids: string[]) => {
      return _uniqBy(_flatMap(Object.values(_pick(tagCatalog.value, ids))), x => x.key)
    }

    return {
      hydratedTags,
      tagCatalog,
      commonTagsForIds,
      allTagsForIds,
    }
  }
}